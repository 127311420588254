import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js"

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>

<MDXTag name="p" components={components}>{`I’ve been working on user documentation for
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://boardgamelab.app/"}}>{`Boardgame Lab`}</MDXTag>{`. As is the case for most
product documentation, screenshots are the most time consuming aspect
of this process.`}</MDXTag>
<MDXTag name="p" components={components}>{`Generating them is one half of the picture. Keeping them up to date
as the product evolves is another challenge.`}</MDXTag>
<MDXTag name="p" components={components}>{`Fortunately, there is a way to automate this.`}</MDXTag>
<video width="100%" height="auto" autoPlay loop muted playsInline poster="/autogenerate-screenshot.png">
  <source src="/autogenerate-screenshot.mp4" type="video/mp4" />
</video>

<MDXTag name="h3" components={components}>{`E2E Testing Tools`}</MDXTag>
<MDXTag name="p" components={components}>{`Turns out that most E2E testing tools for the web have the ability to
generate screenshots. I use `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://github.com/microsoft/playwright"}}>{`Playwright`}</MDXTag>{`
with tests written using `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://codecept.io/"}}>{`CodeceptJS`}</MDXTag>{` (I find the
API more readable than using Playwright directly).`}</MDXTag>
<MDXTag name="p" components={components}>{`Other tools like `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://github.com/puppeteer/puppeteer"}}>{`Puppeteer`}</MDXTag>{` and
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.cypress.io/"}}>{`Cypress`}</MDXTag>{` have similar capabilities, so pick one
that you’re comfortable with.`}</MDXTag>
<MDXTag name="p" components={components}>{`The code to generate a screenshot in `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://codecept.io/"}}>{`CodeceptJS`}</MDXTag>{` looks something like this:`}</MDXTag>
<div className="gatsby-highlight" data-language="js"><pre style={{counterReset: 'linenumber NaN'}} className="language-js line-numbers"><code className="language-js"><span className="token function">Feature</span><span className="token punctuation">(</span><span className="token string">'Test'</span><span className="token punctuation">)</span><span className="token punctuation">;</span>{"\n"}{"\n"}<span className="token function">Scenario</span><span className="token punctuation">(</span><span className="token string">'save screenshot'</span><span className="token punctuation">,</span> <span className="token punctuation">(</span><span className="token constant">I</span><span className="token punctuation">)</span> <span className="token operator">=&gt;</span> <span className="token punctuation">{"{"}</span>{"\n"}{"  "}<span className="token constant">I</span><span className="token punctuation">.</span><span className="token function">amOnPage</span><span className="token punctuation">(</span><span className="token string">'/'</span><span className="token punctuation">)</span><span className="token punctuation">;</span>{"\n"}{"  "}<span className="token constant">I</span><span className="token punctuation">.</span><span className="token function">saveScreenshot</span><span className="token punctuation">(</span><span className="token string">'screenshot.png'</span><span className="token punctuation">)</span><span className="token punctuation">;</span>{"\n"}<span className="token punctuation">{"}"}</span><span className="token punctuation">)</span><span className="token punctuation">;</span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /><span /><span /><span /></span></pre></div>

<MDXTag name="h3" components={components}>{`Hooking it up with your build process`}</MDXTag>
<MDXTag name="p" components={components}>{`We want to add our screenshot generating code directly to the Markdown
file holding the user documentation and have it automagically replace
that with an image of the final screenshot.`}</MDXTag>
<div className="gatsby-highlight" data-language="markdown"><pre style={{counterReset: 'linenumber NaN'}} className="language-markdown line-numbers"><code className="language-markdown"><span className="token title important"><span className="token punctuation">#</span> Boardgame Lab documentation</span>{"\n"}{"\n"}Documentation text.{"\n"}{"\n"}<span className="token tag"><span className="token tag"><span className="token punctuation">&lt;</span>screenshot</span><span className="token punctuation">&gt;</span></span>{"\n"}{"  "}I.amOnPage("/"){"\n"}<span className="token tag"><span className="token tag"><span className="token punctuation">&lt;/</span>screenshot</span><span className="token punctuation">&gt;</span></span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /><span /><span /><span /><span /></span></pre></div>

<MDXTag name="p" components={components}>{`The general strategy is to extract the content of `}<code className="language-text">&lt;screenshot&gt;</code>
{` tags
and copy them over to JavaScript files which are then
converted to screenshots via `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://codecept.io/"}}>{`CodeceptJS`}</MDXTag>{`.
The way I’ve set it up, the extraction logic also provides the boilerplate,
including the `}<code className="language-text">saveScreenshot</code>
{` line.`}</MDXTag>
<img alt="diagram" src="/autogenerate-screenshot-diagram.png" />

<MDXTag name="p" components={components}>{`This can be done in many ways. My documentation site is written in
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://svelte.dev/"}}>{`Svelte`}</MDXTag>{` and uses `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://github.com/pngwn/MDsveX"}}>{`MDsveX`}</MDXTag>{`,
which provides a convenient `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://mdsvex.com/docs#remarkplugins--rehypeplugins"}}>{`hook`}</MDXTag>{`
to parse HTML tags from Markdown files.`}</MDXTag>
<MDXTag name="p" components={components}>{`In theory you could use something other than HTML tags to demarcate
the JavaScript code, but this format allows passing in additional
parameters which are useful. For example, you could specify the size
of the screenshot or the actual element you want to screenshot (if
you don’t want to capture the entire page).`}</MDXTag>
<div className="gatsby-highlight" data-language="markdown"><pre style={{counterReset: 'linenumber NaN'}} className="language-markdown line-numbers"><code className="language-markdown"><span className="token tag"><span className="token tag"><span className="token punctuation">&lt;</span>screenshot</span> <span className="token attr-name">width</span><span className="token attr-value"><span className="token punctuation">=</span><span className="token punctuation">"</span>640<span className="token punctuation">"</span></span> <span className="token attr-name">height</span><span className="token attr-value"><span className="token punctuation">=</span><span className="token punctuation">"</span>480<span className="token punctuation">"</span></span><span className="token punctuation">&gt;</span></span>{"\n"}{"  "}I.amOnPage("/"){"\n"}<span className="token tag"><span className="token tag"><span className="token punctuation">&lt;/</span>screenshot</span><span className="token punctuation">&gt;</span></span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /></span></pre></div>

<MDXTag name="h3" components={components}>{`Concluding Thoughts`}</MDXTag>
<MDXTag name="p" components={components}>{`I like this approach because not only does it automate the generation of
screenshots, the code to generate those screenshots are actual tests that
serve to validate the correctness of the application!`}</MDXTag>
<MDXTag name="p" components={components}>{`Code: `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://github.com/boardgamelab/docs/blob/master/screenshot.js"}}>{`link`}</MDXTag></MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  